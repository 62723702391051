<template>
  <div class="row">
    <router-link
      v-for="(pantalla, index) in pantallas"
      :key="index"
      :to="pantalla.route"
      class="col-lg-3 col-sm-6"
    >
    <button class="btn btn-sexduceme my-1 btn-block">
      <h2><i :class="'fa ' + pantalla.fa"></i></h2>{{ pantalla.text }}
    </button>
      </router-link>
  </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {
      cargando: false,
      destacados: [],
      descuentos: [],
      pantallas: [
        {
          text: "Punto de Venta",
          fa: " fa-shopping-cart",
          route: "/punto-venta",
        },
        {
          text: "Inventario",
          fa: "fa-book",
          route: "/inventario/productos",
        },
        {
          text: "Ventas",
          fa: "fa-th-list",
          route: "/ventas",
        },
        {
          text: "Tienda",
          fa: "fa-shopping-bag",
          route: "/tienda",
        },
        {
          text: "Mantenedor de Productos",
          fa: "fa-cart-plus",
          route: "/mantenedor/productos",
        },
        {
          text: "Mantenedor de Compras",
          fa: "fa-truck",
          route: "/mantenedor/compras",
        },
        {
          text: "Informes",
          fa: "fa-wpexplorer",
          route: "/ventas",
        },
      ],
    };
  },
  methods: {
    cargarDestacados() {
      const axios = require("@/assets/functions/axiosClient.js");
      axios
        .get("/inventarios/productos/destacados")
        .then((response) => {
          this.cargando = true;
          this.destacados = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    cargarDescuentos() {
      const axios = require("@/assets/functions/axiosClient.js");
      axios
        .get("/inventarios/productos/descuentos")
        .then((response) => {
          this.cargando = true;
          this.descuentos = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.cargando = false;
        });
    },
  },

  metaInfo() {
    return {
      title: "Sexshop Sexduceme",
    };
  },
  created() {},
};
</script>

<style>

.btn-sexduceme {
  background-color: #740b33;
  color: #ffffff;
}

.btn-sexduceme:hover {
  background-color: #ffffff;
  color: #740b33;
}

</style>