import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/carro',
    name: 'Carro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CarroCompra.vue')
  },
  {
    path: '/tienda/:categoria?',
    name: 'Tienda',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tienda.vue')
  },
  {
    path: '/producto/:codigo/:nombre',
    name: 'detalleProducto',
    component: () => import(/* webpackChunkName: "about" */ '../views/DetalleProducto.vue')
  },
  {
    path: '/inventario/productos',
    name: 'Inventario',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inventario.vue')
  },
  {
    path: '/mantenedor/productos',
    name: 'AgregarProducto',
    component: () => import(/* webpackChunkName: "about" */ '../views/MantenedorProducto.vue')
  },
  {
    path: '/mantenedor/compras',
    name: 'agregarCompras',
    component: () => import(/* webpackChunkName: "about" */ '../views/MantenedorCompras.vue')
  },
  {
    path: '/punto-venta',
    name: 'agregarVentas',
    component: () => import(/* webpackChunkName: "about" */ '../views/MantenedorVentas.vue')
  },
  {
    path: '/pos',
    name: 'pos-venta',
    component: () => import(/* webpackChunkName: "about" */ '../views/PosVenta.vue')
  },
  {
    path: '/ventas',
    name: 'centas',
    component: () => import(/* webpackChunkName: "about" */ '../views/Ventas.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/test/:param?',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/Test.vue')
  },

  // { 
  //   path: '*',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from) => {
  console.log(from.path+" -> "+to.path)
  let credenciales = JSON.parse(localStorage.getItem('credentials'));
  if(!credenciales && to.path!=='/login'){
    router.push("/login");
    alert("Inicia Sesión");
  }

});

export default router
