<template>
  <div id="app">
  <div class="h-25">
    <Header class="h-50"></Header>
    <div class="d-flex justify-content-around align-items-center h-50">
      <div>
          <img alt="logo" class="h-100" src="@/assets/logoAjustado.png">
      </div>
  </div>
  </div>
  
  
      

    <router-view/>

    <Footer></Footer>
    
  </div>
</template>

<script>
// import { library } from '@fortawesome/fontawesome-svg-core'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// library.add(faUserSecret)

// import Header from '@/components/HeaderElectro.vue'
import Header from '@/components/HeaderBootstrap.vue'
import Footer from '@/components/FooterBootstrap.vue'


export default {
  components:{ Header, Footer },
  data(){
    return{
    }
  },
  methods:{
  },
  created(){
    // if(!localStorage.getItem('credentials')){
    //           let url = "/login";
    //     if(window.location.pathname !=="/login"){
    //         url += "?redirect="+window.location.pathname;
    //     }
    //   this.$router.push(url)
    // }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: white;
   /* rgb(251,212,228) */
   /* #F5F5DC; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

router-link{
  color:white
}

.btn-fb[data-v-7b876761] {
    background-color: #3b5998;
    color: #f5f5f5;
    font-size: large;
}
.btn-fb[data-v-7b876761]:hover {
    background-color: #f5f5f5;
    color: #3b5998;
    font-size: large;
    border-style: solid;
    border-color: #3b5998;
}

.btn-wp[data-v-7b876761] {
    color: #f5f5f5;
    background-color: #1c8b82;
    font-size: large;
}

.btn-wp[data-v-7b876761]:hover {
    color: #1c8b82;
    background-color: #f5f5f5;
    font-size: large;
    border-style: solid;
    border-color: #1c8b82;
}

.btn-ig[data-v-7b876761] {
    color: #f5f5f5;
    background-color: #305777;
    font-size: large;
}

.btn-ig[data-v-7b876761]:hover {
    color: #305777;
    background-color: #f5f5f5;
    font-size: large;
    border-style: solid;
    border-color: #305777;
}

a:hover{
  text-decoration: none;
}

.logo{
  width: 45%;
}
.fondo-transparente{
  background-color: transparent!important;
}

.w-100{
        width: 100%;
    }
.h-100{
  height: 100%;
}
.br-20{
        border-radius: 20%;
    }
.sin-borde{
        border-style: none;
    }

</style>