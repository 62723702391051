<template>
  <div id="footer" class="mt-3 fondo-fucsia borde-rosa">
    <footer class="row">
      <!-- <img alt="logo" class="col-4 h-10" src="@/assets/logoAjustado.png"> -->
      <div class="col-4">Tu Sexshop en la Región de Coquimbo</div>
      <div class="col-4">Parque Magallanes #1460. Bosque San Carlos, Coquimbo</div>
    </footer>
  </div>
</template>


<style>
.fondo-fucsia{
  background-color: #bb1253;
  color: white
}
.borde-rosa{
  border-top: 5px solid #740b33
}
.h-10{
  height: 10%;
  width: auto;
}
.w-10{
  height: auto;
  width: 10%;
}
.w-15{
  height: auto;
  width: 15%;
}
.fondo-blanco-semi{
  background-color: rgba(255 , 255, 255, 0.5),

}
</style>