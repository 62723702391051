<template>
    <div>
        <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <a class="navbar-brand" href="#">Sexdúceme Sexshop</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto row">
          <li class="nav-item active">
            <a class="nav-link"><router-link to="/"><span class="text-light"><i class="fa fa-home mx-2" aria-hidden="true" data-v-7b876761=""></i>Inicio</span></router-link> <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item" v-for="(pantalla,index) in getShow(pantallas)" :key="index">
            <a class="nav-link"><router-link :to="pantalla.route"><span class="text-light"><i :class="'fa '+ pantalla.fa+' mx-2'" aria-hidden="true" data-v-7b876761=""></i>{{pantalla.text}}</span></router-link> <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="text-light"><i class="fa fa-shopping-bag mx-2" aria-hidden="true" data-v-7b876761=""></i>Tienda</span>
            </a>
            <div class="dropdown-menu bg-secondary" aria-labelledby="navbarDropdown">
              <router-link @click="obtenerProductosCategoria(0)" class="dropdown-item" to="/tienda"><span class="text-light">Todos los Productos</span></router-link>
              <div class="dropdown-divider"></div>
              <router-link @click="obtenerProductosCategoria(categoria.id)" v-for="categoria in categorias" :key="categoria.id" class="dropdown-item" :to="'/tienda/'+categoria.nombre"><span class="text-light">{{categoria.nombre}}</span></router-link>
            </div>
          </li>
          <div class="">
            <li v-if="!logueado" class="nav-item">
              <a class="nav-link"><router-link to="/login"><span class="text-light"><i class="fa fa-sign-in mx-2" aria-hidden="true" data-v-7b876761=""></i>Iniciar Sesión</span></router-link></a>
            </li>
            <li v-if="logueado" class="nav-item seleccionable" @click="cerrarSesion()">
              <a class="nav-link"><span class="text-light"><i class="fa fa-sign-out mx-2" aria-hidden="true" data-v-7b876761=""></i>Cerrar Sesión</span></a>
            </li>
          </div>
          
        </ul>
        
        <!-- <form class="form-inline my-2 my-lg-0 d-flex justify-content-center">
          <input class="form-control mr-sm-2 col" type="search" placeholder="Buscar Productos" aria-label="Search">
          <button class="btn btn-outline-success my-2 ml-1 my-sm-0" type="submit"><i class="fa fa-search" aria-hidden="true" data-v-7b876761=""></i> </button>
        </form> -->
            </div>
        </nav>
    </div>
</template>

<script>
const axios = require('@/assets/functions/axiosClient.js');
export default {
  data(){
    return{
      categorias:[],
      logueado: null,
      pantallas: [
        {
          text: "Punto de Venta",
          fa: " fa-shopping-cart",
          route: "/punto-venta",
          show: true
        },
        {
          text: "Inventario",
          fa: "fa-book",
          route: "/inventario/productos",
          show: true
        },
        {
          text: "Ventas",
          fa: "fa-th-list",
          route: "/ventas",
          show: true
        },
        {
          text: "Tienda",
          fa: "fa-shopping-bag",
          route: "/tienda",
          show: false
        },
        {
          text: "Mantenedor de Productos",
          fa: "fa-cart-plus",
          route: "/mantenedor/productos",
          show: false
        },
        {
          text: "Mantenedor de Compras",
          fa: "fa-truck",
          route: "/mantenedor/compras",
          show: false
        },
        {
          text: "Informes",
          fa: "fa-wpexplorer",
          route: "/ventas",
          show: false
        },
      ],
    }
  },
  methods:{
    cerrarSesion(){
      let credenciales = JSON.parse(localStorage.getItem('credentials'));
      console.log(credenciales);
      axios
        .delete('/sesiones/usuarios/'+credenciales.usuarioId)
        .then(response =>{
          if(response.status === 201){
            localStorage.removeItem('credentials');
            this.logueado=false;
            window.location.href='/login';
          }
        })
        .catch(error => {
          console.log(error.response);
          alert("No se pudo cerrar Sesion");
        })
      
    },
    getShow(pantallas){
      let filter = [];
      pantallas.forEach(pantalla => {
        if(pantalla.show){
          filter.push(pantalla);
        }
      });

      return filter;
    },
    obtenerCategorias(){
      this.cargando = true;
      axios
        .get('/inventarios/categorias/principales')
        .then(response => {
          if(response.status===200){
            this.categorias = response.data.data;
          }
          else if(response.status===204){
            this.mensaje="No hay categorías en el sistema";
          }
          
          })
          .catch(error=>{
            console.log(error);
          })
        .finally(()=>{
        }
        )
    }
  },
  created(){
    this.logueado = localStorage.getItem('credentials')?true:false;
    this.obtenerCategorias();
  }
}
</script>

<style>

  .seleccionable{
    cursor: pointer
  }
</style>