import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  mutations: {
    formatPrice(price){
        // return price;
        let priceString = price.toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1.');
        return '$ ' + priceString.substring(0,priceString.length-2);
    },
    esMovil(){
      return (
        (navigator.userAgent.match(/Android/i)) ||
        (navigator.userAgent.match(/webOS/i)) ||
        (navigator.userAgent.match(/iPhone/i)) ||
        (navigator.userAgent.match(/iPod/i)) ||
        (navigator.userAgent.match(/iPad/i)) ||
        (navigator.userAgent.match(/BlackBerry/i))
      );
    }
  },
  actions: {
  },
  modules: {
  }
})
